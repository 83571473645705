<template>
  <div v-if="initOk">
    <div class="block">
      <div class="title"><span>1</span> 商品规格</div>
      <Select :skus="skus" :catAttrs="catAttrs" :materials="materials" :colors="colors" @onSuccess="getBaseInfo" @onAdd="onAdd"></Select>
    </div>
    <div class="block">
      <div class="title"><span>2</span> 报价</div>
      <Skus2Sale v-if="skuType==1" :selected="selected" v-model="newSkus" :materials="materials" :colors="colors" :inCatAttrs="catAttrs" :module_no="module_no" ref="skus"></Skus2Sale>
      <Skus v-else :selected="selected" v-model="newSkus" :materials="materials" :colors="colors" :inCatAttrs="catAttrs" :module_no="module_no" ref="skus"></Skus>
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import { getFacCatDetail } from "@/service/item";
import { materialList, colorList } from "@/service/item";
import Select from "./Select";
import Skus from "./Skus";
import Skus2Sale from "./Skus2Sale"; // 集市自有商品的Sku
export default {
  components: { Select, Skus, Skus2Sale },
  model: {
    prop: "skus",
    event: "change"
  },
  props: ["skus", "catId","module_no","skuType"],
  data() {
    return {
      catAttrs:null,
      newSkus: this.skus,
      materials: null,
      colors: null,
      selected: null,
      initOk:false
    };
  },
  watch: {
    newSkus: {
      handler: function(newVal, oldVal) {
        this.$emit("change", newVal);
      },
      deep: true
    }
  },
  computed: {
    // _selected: function () {
    //     return this.selected
    // },
  },
  async created() {
    let tempCatAttrs = await getFacCatDetail(this.catId);
    this.catAttrs = sortBy(tempCatAttrs,["name"]);
    // this.cleanAttrs();
    await this.getBaseInfo();
    this.initOk=true;
    
  },
  methods: {
    async getBaseInfo() {
      this.materials = await materialList({hasCommon:true});
      this.colors = await colorList({hasCommon:true});
    },
    onAdd(event){
      this.selected=event;
      // this.valid().then(()=>{
      //   this.selected=event;
      // }).catch(err=>{
      //   console.log(err);
      //   this.$message.error(err.message);
      // });
    },
    async valid(){
      await this.$refs.skus.valid();
      console.log("sku.Edit.valid",this.newSkus);
    }
  }
};
</script>
<style scoped>
.block {
  margin: 0 0 20px 0;
  padding: 20px;
}
.block .title {
  font-size: 16px;
  font-weight: 600;
  color: #4D4D4D;
  line-height: 22px;
  padding-bottom: 20px;
}
.block .title span {
  color: #5074EE;
}
</style>