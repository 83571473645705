<template>
  <div>
    <el-form :inline="true" :model="batchObj" class="batch-ipt">
      <el-form-item>
        <el-input v-model="batchObj.price_lv_4" size="small" placeholder="建议零售价" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="batchObj.price_lv_3" size="small" placeholder="三级批发价" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="batchObj.price_lv_2" size="small" placeholder="二级批发价" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="batchIpt">批量填充</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  data() {
    return {
      batchObj:{price_lv_4:null,price_lv_2:null,price_lv_3:null}
    };
  },
  async created() {},
  methods: {
    batchIpt(){
      if(this.batchObj.price_lv_4||this.batchObj.price_lv_2||this.batchObj.price_lv_3){
        this.$emit("onSubmit", cloneDeep(this.batchObj));
        this.batchObj.price_lv_4=null;
        this.batchObj.price_lv_2=null;
        this.batchObj.price_lv_3=null;
      }
    }
  }
};
</script>
<style scoped>
.batch-ipt{
  text-align: right;
}
</style>