<template>
  <div>
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px">
      <div class="block-bg">
          <el-form-item label="材质" prop="material">
            <el-select v-model="ruleForm.material" @change="changeAttr" @remove-tag="validForm" multiple placeholder="请选择" style="width:350px">
              <el-option v-for="item in materials" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button @click="edit(1)" style="margin-left:60px" type="primary" plain>增加属性值</el-button>
        </el-form-item>
        <el-form-item style="margin-bottom:0;" label="颜色" prop="color">
            <el-select v-model="ruleForm.color" @change="changeAttr" @remove-tag="validForm" multiple placeholder="请选择" style="width:350px">
              <el-option v-for="item in colors" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button @click="edit(2)" style="margin-left:60px" type="primary" plain>增加属性值</el-button>
        </el-form-item>
      </div>
      <!-- <div class="block-bg">
        <el-form-item style="margin-bottom:0;"
            v-for="(attr, index) in ruleForm.attrs"
            :key="index"
            :prop="'attrs.' + index + '.value'"
            :rules="rules.attr"
            :label="attr.name">
          <el-select v-model="attr.value" multiple placeholder="请选择" style="width:400px">
              <el-option v-for="item in attr.items" :key="item.id" :label="item.value" :value="item"></el-option>
          </el-select>
          <el-button @click="edit()" style="margin-left:50px" type="primary" plain>增加属性值</el-button>
        </el-form-item>
      </div> -->
      <div v-if="ruleForm.attrs && ruleForm.attrs.length>0">
        <el-form-item style="margin-bottom:10px;padding:20px 0" class="block-bg"
            v-for="(attr,idx) in ruleForm.attrs" :key=idx 
            :prop="'attrs.' + idx + '.value'"
            :rules="rules.attr"
            :label="attr.name">
          <div class="flex-1 flex flex-align-center">
            <el-select v-model="attr.value" @change="changeAttr" @remove-tag="validForm" multiple placeholder="请选择" style="width:350px">
                <el-option v-for="item in attr.items" :key="item.id" :label="item.value" :value="item"></el-option>
            </el-select>
            <el-button @click="edit(3,attr,idx)" style="margin:0 60px" type="primary" plain>增加属性值</el-button>
            <div>
              <a @click="del(attr,idx)" class="noselect" style="font-size:14px;color:#5074EE;">删除规格</a>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="block-bg flex flex-align-center" style="padding:20px">
        <el-select v-model="attrId" placeholder="请选择" style="width:130px">
            <el-option v-for="(item,idx) in catAttrs" :key="idx" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div @click="addSpec()" class="add noselect"><span>+</span>增加规格</div>
        <el-popover style="margin-left:20px"
          placement="top-start"
          width="400"
          trigger="hover"
          content="未找到您需要的属性规格，请联系客服：18979787390 ">
          <i class="add-icon el-icon-warning" slot="reference"></i>
        </el-popover>
      </div>
      <!-- <div>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button type="primary" @click="checkAlert">编辑销售属性表</el-button>
      </div> -->
    </el-form>

    <el-dialog width="650px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
        <div v-if="popTitle=='温馨提醒'" class="alert">
          <div class="alert-text">
              <span v-if="checkType==0">删除此列</span>
              <span v-if="checkType==1">增加规格</span>
              会清空填写数据，是否确定操作？</div>
          <div style="width:60%;padding:30px 0;margin:0 auto;" class="flex flex-justify-around">
              <el-button @click="hide()" size="small" type="primary">取消</el-button>
              <el-button @click="checkSubmit()" size="small" type="primary">确认</el-button>
          </div>
        </div>
        <EditAttr v-else :itemId="itemId" :popTitle="popTitle" @hide="popVisible=false" @onSuccess="onSuccess"></EditAttr>
    </el-dialog>

  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { catAttrValueList } from "@/service/item";
import EditAttr from './EditAttr.vue';
export default {
  components: {
      EditAttr,
  },
  props: ["skus", "catAttrs","materials","colors"],
  data() {
    return {
      popTitle: null,
      popVisible: false,
      skuList:[],
      ruleForm: {
        material: [],
        color: [],
        attrs: []
      },
      rules: {
        material: [
          { required: true, message: "请至少选择一个材质", trigger: "blur" }
        ],
        color: [
          { required: true, message: "请至少选择一个颜色", trigger: "blur" }
        ],
        attr: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value && value.length > 0) {
                callback();
              } else {
                let _idx = rule.field.split(".")[1];
                let _name = this.ruleForm.attrs[_idx].name;
                callback(new Error(`${_name} 至少选择一项!`));
              }
            },
            trigger: "blur"
          }
        ]
      },
      attrId:null, // 类目属性id
      attrIndex:null, // 类目属性Index
      itemId:null, // 传给自定义属性值的ID
      checkType: 0, // 0：删除属性，1：添加属性
      delIndex:null,
    };
  },
  watch: {},
  computed: {},
  created() {
      this.skuList = this.skus;
      console.log("catAttrs",this.catAttrs);
      console.log("skus",this.skuList);
      if (this.skus && this.skus.length>0) {
          this.echo();
      }
  },
  methods: {
    goto(path){
      this.$router.push(path)
    },
    echo() {
        this.skus.forEach(e => {
            if (this.ruleForm.material.indexOf(e.material)==-1) {
                this.ruleForm.material.push(e.material);
            }
            if (this.ruleForm.color.indexOf(e.color)==-1) {
                this.ruleForm.color.push(e.color);
            }
            e.attrs.forEach((n)=>{
                let attr = this.ruleForm.attrs.findIndex((v) => {
                    return v.id == n.id;
                });
                if (attr==-1) {
                    this.ruleForm.attrs.push({
                        id: n.id,
                        name: n.name,
                        items: [],
                        value: [],
                    });
                }
                if (this.ruleForm.attrs && this.ruleForm.attrs.length>0) {
                    this.ruleForm.attrs.forEach((k)=>{
                        let attrVal = k.value.findIndex((v) => {
                            return v.id == n.valueId;
                        });
                        if (k.id == n.id && attrVal==-1) {
                            k.value.push({
                                id: n.valueId,
                                value: n.value,
                            })
                        }
                    })
                }
            
            })
        });
        this.ruleForm.attrs.forEach((e)=>{
            catAttrValueList(e.id,{hasCommon:true}).then((res)=>{
                e.items = res;
            })
        })
        console.log(this.ruleForm);
    },
    // type: 1、材质，2、颜色，3、类目
    edit(type,item,idx) {
        if (type == 1) {
            this.popTitle = "材质";
        } else if (type == 2) {
            this.popTitle = "颜色";
        } else if (type == 3) {
            this.itemId = item.id;
            this.attrIndex = idx;
            this.popTitle = "类目";
        }
        this.popVisible = true;
    },
    async onSuccess() {
      this.$emit('onSuccess');
      await this.updateSpec();
    },
    // 校验是否添加重复的规格属性
    checkSpec() {
      let flag = true;
      if (this.ruleForm.attrs && this.ruleForm.attrs.length>0) {
        this.ruleForm.attrs.forEach((e)=>{
          if (e.id == this.attrId) {
            flag = false;
          }
        })
      }
      return flag;
    },
    addSpec() {
      if (!this.attrId) {
        return this.$message.error("请选择要添加的规格属性");
      }
      if (!this.checkSpec()) {
        this.$message.error("不能添加重复的规格属性")
      } else {
        if (this.skuList && this.skuList.length>0) {
            this.$confirm("增加规格后，会根据选中的值清空相应数据，是否确定操作？", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning",
            }).then(() => {
                this.addSpecSubmit();
            });
        } else {
            this.addSpecSubmit();
        }
      }
      // if (this.ruleForm.attrs && this.ruleForm.attrs.length>=2) {
      //   return this.$message.error("最多只能添加两个");
      // }
      // this.checkType = 1;
      // this.checkAlert();
        
    },
    addSpecSubmit() {
        const _loading = this.$loading({
            lock: true,
            text: '拼命加载中......',
        });
        this.add_spec().then((res)=>{
            _loading.close();
            this.$message.success("新增规格成功");
        })
        this.add();
    },
    async add_spec() {
        let obj = this.catAttrs.find((e)=> {
            return e.id == this.attrId;
        })
        let catAttrValList = await catAttrValueList(this.attrId,{hasCommon:true});
        if (catAttrValList) {
            this.ruleForm.attrs.push({
              id: obj.id,
              name: obj.name,
              items: catAttrValList,
              value: [],
            });
        }
        console.log("attrs",this.ruleForm.attrs);
    },
    async updateSpec() {
        if (this.itemId) {
            let catAttrValList = await catAttrValueList(this.itemId,{hasCommon:true});
            this.ruleForm.attrs[this.attrIndex].items = catAttrValList;
        }
    },
    del(item,idx) {
        // this.checkType = 0;
        // this.delIndex = idx;
        // this.checkAlert();
        let text = null;
        if (this.skuList && this.skuList.length>0 && item.value && item.value.length>0) {
            text = "删除此列会清空填写数据，是否确定操作？";
        } else {
            text = "确认是否删除";
        }
      this.$confirm(text, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        this.ruleForm.attrs.splice(idx,1);
        this.$message.success("删除成功");
        this.add();
      });
    },
    validForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid, obj) => {
          if (valid) {
            resolve(true);
          } else {
            // console.log(obj);
            return reject(new Error(obj[Object.keys(obj)[0]][0].message));
          }
        });
      });
    },
    reset(){
      this.$refs['ruleForm'].resetFields();
    },
    changeAttr(items) {
        // console.log(items);
        if (items && items.length>0) {
            this.validForm();
            this.add();
        }
    },
    // 更新sku属性值
    add(){
      let values = cloneDeep(this.ruleForm);
      if(values.attrs && values.attrs.length>0){
          for(let attr of values.attrs){
              delete attr.items;
          }
      }
      console.log("values",values);
      if (values.material && values.material.length>0 && values.color && values.color.length>0) {
          this.$emit("onAdd", values);
      }
    },
    // 校验
    checkAlert() {
      console.log(this.$emit('check'));
      this.validForm().then(()=>{
        if (this.$parent.newSkus && this.$parent.newSkus.length>0) {
          this.popTitle = "温馨提醒";
          this.popVisible = true;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 校验类型
    checkSubmit() {
        if (this.checkType==1) {
            this.add_spec();
            this.hide();
        } else if (this.checkType==0) {
            this.ruleForm.attrs.splice(this.delIndex,1);
            this.delIndex = null;
            this.$message.success("删除成功");
            this.hide();
            this.add();
        }
        this.checkType = null;
    },
    hide() {
      this.popVisible = false;
    },
  }
};
</script>
<style scoped>
.block-bg {
    padding: 16px 0;
    background: #FAFAFA;
    margin-bottom: 10px;
}
.add {
  width: 130px;
  padding: 0 20px;
  font-size: 16px;
  color: #5074EE;
  line-height: 24px;
  cursor: pointer;
}
.add span:first-child {
  font-size: 22px;
  position: relative;
  top: 1px;
  margin-right: 2px;
}
.add-icon {
  font-size: 16px;
  color: #FAAD14;
  cursor: pointer;
}
.alert .alert-text {
  padding: 30px 0;
  text-align: center;
  font-size: 15px;
}
</style>